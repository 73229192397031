import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import { compact } from 'lodash';
import { graphql, PageProps } from 'gatsby';

import SEO from '../../components/SEO/SEO';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import Breadcrumb from '../../components/Breadcrumb';
import SchoolLocationCard from '../../components/SchoolLocationCard';
import HeroComponent from '../../components/HeroComponent/HeroComponent';
import { StrapiImageAndTextComponent } from '../../../src/components/StrapiComponents/StrapiImageAndTextComponent';
import { StrapiInlineCtaComponent } from '../../components/StrapiComponents/StrapiInlineCtaComponent';
import { SingleCertificationCityPageQuery, InlineCtaComponentFragment, Scalars } from '../../../graphql.schema';
import { GlobalPageContext } from '../../types';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import translations from '../../translations/en.json';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './certification-post.module.scss';

type PageContextType = GlobalPageContext & {
  id: Scalars['ID'];
  cityId: Scalars['ID'];
};

const CertificationCityPage: React.FC<PageProps<SingleCertificationCityPageQuery, PageContextType>> = ({
  pageContext,
  data,
}) => {
  const { certification, city } = data.strapi;
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3.id, ...websiteLocaleV3.attributes });
  const intl = useIntl();
  const showFooterCta = city?.pageSettings?.showFooterCta ?? false;
  const footerCta = city?.pageSettings?.customFooterCta?.id
    ? city?.pageSettings?.customFooterCta
    : websiteLocale.footerCta;

  if (!websiteLocale || !city?.name)
    return (
      <p>
        <FormattedMessage id="notFoundTextHeading" />
      </p>
    );

  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={ intl.formatMessage(
          {
            id: 'seo.certification-city.title',
            defaultMessage: translations['seo.certification-city.title'],
          },
          {
            city: city?.name || '',
            siteTitle: pageContext.websiteLocale.name,
            certification: certification?.name || '',
          },
        )}
        description={ intl.formatMessage(
          {
            id: 'seo.certification-city.description',
            defaultMessage: translations['seo.certification-city.description'],
          },
          {
            city: city?.name || '',
            certification: certification?.name || '',
          },
        )}
        image={city.seo?.metaImage?.url}
        avoidIndexing={city.pageSettings?.avoidIndexing ?? false}
        canonicalURL={city.seo?.canonicalURL}
        keywords={city.seo?.keywords}
        metaSocial={city.seo?.metaSocial}
        structuredData={city.seo?.structuredData}
      />
      <div className={classnames(styles.main, 'container')}>
        <Breadcrumb pageContext={pageContext} lastCrumb={`${certification?.name} ${city.name}`}></Breadcrumb>
        <HeroComponent
          title={intl.formatMessage(
            {
              id: 'certificationInCity',
              defaultMessage: '{certification} in {city}',
            },
            { certification: certification?.name, city: city.name },
          )}
          image={city?.image}
          intro={city?.description}
          button={city?.heroLink}
        />
      </div>
      <div className="container">
        <div className={styles.schoolsWrapper}>
          {compact(city.schools).map((school, i) => (
            <React.Fragment key={`${school}-${i}`}>
              <SchoolLocationCard
                schoolSlugBase={pageContext.pathPrefixes.schools}
                certificationName={certification?.name ?? ''}
                mapLocation={school?.mapMarker?.mapLocation}
                {...school}
              />
            </React.Fragment>
          ))}
        </div>
        {city?.imageAndText && <StrapiImageAndTextComponent {...city.imageAndText} />}
      </div>
      {certification?.footerCta ? (
        <StrapiInlineCtaComponent {...(certification.footerCta as InlineCtaComponentFragment)} />
      ) : (
        showFooterCta && footerCta && <StrapiFooterCtaComponent {...footerCta} />
      )}
    </LayoutWrapper>
  );
};

export const query = graphql`
query SingleCertificationCityPage($id: ID!, $cityId: ID!) {
  strapi {
    certification(id: $id) {
      data {
        id
        attributes {
          name
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          image {
            ...StrapiImageEntityResponse
          }
          footerCta: footer_cta {
            ...InlineCTAComponent
          }
        }
      }
    }
    city(id: $cityId) {
      data {
        id
        attributes {
          name
          description
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          image {
            ...StrapiImageEntityResponse
          }
          heroLink: hero_link {
            ...LinkComponent
          }
          imageAndText: image_and_text {
            ...ImageAndTextComponent
          }
          schools {
            data {
              id
              attributes {
                ...School
              }
            }
          }
          pageSettings: page_settings {
            ...PageSettings
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;

export default intlWrapperHOC(v4tov3HOC(CertificationCityPage));
